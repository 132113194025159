@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

* {
  font-family: 'Source Code Pro', sans-serif;
  
}

html {
  font-family: 'Source Code Pro', monospace; 
  font-style: normal; 
  line-height: 1.4em; 
  font-size: 0.7rem; 
  background-color: #0F1C3F !important;
  color: white;
}

.chakra-ui-light 
{
  background-color: #0F1C3F;
  color: #F9E9B9;
}

.chakra-button {
  background-color: #fff;
}


code {
  font-family: 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.critical { 
  color: #fff; 
  background-color:#d63031; 
  padding:3px 8px; 
}
.high { 
  color: #fff;
  background-color: #e17055;
  padding:3px 8px;  
}
.medium { 
  color: #fff;
  background-color: #fdcb6e;
  padding:3px 8px; 
}
.low { 
  color: #fff;
  background-color: #55efc4; 
  padding:3px 8px; 
}
.none { 
  color: #fff;
  background-color: #636e72;
  padding:3px 8px;  
}
