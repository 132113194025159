@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*
.target {
  color:#999;
}

.target:hover {
  color:#D8572A
}*/